import React from 'react';

interface ContentContainerProps {
  children: React.ReactNode;
}

const ContentContainer: React.FC<ContentContainerProps> = props => {
  return <div className="container mx-auto p-4 sm:p-8">{props.children}</div>;
};

export default ContentContainer;
